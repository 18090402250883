<template>
  <PageLayout>
    <template v-slot:header>
      <Header />
    </template>

    <div class="main-page">
      <div class="container" id="revokes-container" :style="`width: ${defaultContainerWidth}`">
        <div class="resizer resizer-right"></div>
        <div class="resizer resizer-left"></div>
        <div class="list-requests">
          <template v-if="isLoaded">
            <template v-if="!isActiveFilter && !getApplicationList.length">
              <div class="list-requests-empty">
                <div class="list-requests-empty-content">
                  <div class="title">
                    <span>Здесь будут ваши заявки</span>
                  </div>
                  <div class="descr">
                    <p>Пока что у вас нет ни одной заявки на отзыв.</p>
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="list-requests-header">
                <div class="title">
                  <template>Список заявок на отзыв</template>
                  <span>Нажмите на строку заявки для просмотра подробной информации</span>
                </div>

                <div class="controls">
                  <FilterSearch :pageName="'revokes'" />

                  <Button @click.prevent="getReport" color="purple-ghost">
                    <IconBase iconName="download-icon" width="14" height="14" viewBox="4 4 16 17" fill="#6F61E9">
                      <IconDownload />
                    </IconBase>
                    &nbsp;<span style="font-size: 16px;">csv</span>
                  </Button>

                  <Button @click.prevent="showCreateRevokeModal" class="button-create">
                    <IconBase width="16" height="16" viewBox="0 0 16 16" fill="#fff">
                      <Plus />
                    </IconBase>
                  </Button>
                </div>
              </div>

              <FilterInfoString :filterInfoString="getFilterInfoString('revokes')" />
              <Table class="list-requests-body">
                <Thead>
                  <TableRow
                    :isTHead="true"
                    :items="getColumnTitles"
                    :cellsWidth="defaultCellsWidth"
                  />
                </Thead>
                <Tbody>
                  <TableRow
                    v-for="items in getApplicationList"
                    :key="items[0].id"
                    :items="items"
                    :cellsWidth="defaultCellsWidth"
                    @click="$router.push(`/revoke/${items[0].id}`)"
                  />
                </Tbody>
              </Table>
              <div v-if="totalPages !== 1" class="pager-wrap">
                <Pager @paginate="changePage" :totalPages="totalPages" :page="activePage" />
                <div class="select">
                  <span>Показывать</span>
                  <Select
                    name="page-size"
                    :value="sizePage"
                    @input="changeSizePage"
                    :options="[
                      { label: '10', value: 10 },
                      { label: '25', value: 25 },
                      { label: '50', value: 50 }
                    ]"
                  />
                  <span>строк таблицы</span>
                </div>
              </div>
            </template>
          </template>
        </div>
      </div>
    </div>

    <ModalWithFields
      name="modal-get-certificates-by-snils"
      width="760px"
      height="395px"
      title="Найти сертификаты заявителя"
      description="Чтобы продолжить процесс создания завки на отзыв сертификата, необходимо найти сертификаты заявителя по его СНИЛС или ОГРН/ОГРНИП"
      :fields="[
        {
          name: 'searchBy', 
          type: 'select', 
          label: 'Искать по параметру', 
          defaultValue: '', 
          options: [
            {value: 'Snils', label: 'СНИЛС'},
            {value: 'Ogrn', label: 'ОГРН/ОГРНИП'}
          ],
          rule: {
            isRequired: true
          }
        },
        {
          name: 'search', 
          type: 'input', 
          label: 'Значение (без пробелов и дефисов)',
          rule: {
            isRequired: true
          }
        },
      ]"
      @onClickOk="getCertificatesBySnilsHandler"
    />

    <ModalWithFields
      name="modal-choose-certificate-to-revoke"
      width="760px"
      height="434px"
      title="Выбрать сертификат для отзыва"
      description="Найдено несколько сертификатов заявителя.
                   Чтобы продолжить процесс создания заявки на отзыв, необходимо выбрать сертификат и причину отзыва"
      :fields="[
        {
          name: 'certificate', 
          type: 'select', 
          label: 'Сертификаты заявителя', 
          defaultValue: '', 
          options: foundCertificatesOptions,
          rule: {
            isRequired: true
          }
        },
        {
          name: 'reason', 
          type: 'select', 
          label: 'Причина отзыва', 
          options: reasonOptions,
          rule: {
            isRequired: true
          }
        },
      ]"
      @onClickOk="createNewRevokeHandler"
    />

    <template v-slot:footer>
      <Footer />
    </template>
  </PageLayout>
</template>

<script>
  import Vue from 'vue';
  import { mapActions, mapState, mapGetters } from 'vuex';

  import {
    Header,
    Footer,
    FilterSearch,
    FilterInfoString
  } from '@/components';
  import {
    Button,
    Table,
    Thead,
    Tbody,
    TableRow,
    Pager,
    IconBase
  } from '@/components/UI';
  import PageLayout from '@/components/layouts/PageLayout.vue';
  import Select from '@/components/UI/form/Select.vue';
  import Plus from '@/components/UI/icon/Plus.vue';
  import IconDownload from '@/components/UI/icon/Download.vue';
  import { MIN_CONTAINER_WIDTH } from '@/constants';
  import ROLES from "@/config/roles";
  import { PAGE_SHORT_NAMES } from '@/config/pages';
  import TABLE_COLUMNS from '@/config/table-columns';
  import ModalWithField from "@/components/ModalWithField.vue";
  import ModalWithFields from "@/components/ModalWithFields.vue";

  import router from "@/router";
  import { getFormattedDate } from '@/helpers/index';

  import {
    REVOKE_REASON_TYPES,
    REVOKE_REASON_TYPE_IDS,
    REVOKE_IDENTIFICATION_TYPE_IDS,
  } from "@/constants/revoke.constants";

  export default Vue.extend({
    name: 'RevokeList',

    components: {
      Button,
      Tbody,
      Thead,
      Table,
      TableRow,
      Select,
      Pager,
      PageLayout,
      Header,
      Footer,
      IconBase,
      Plus,
      IconDownload,
      FilterInfoString,
      FilterSearch,
      ModalWithField,
      ModalWithFields,
    },

    data() {
      return {
        foundCertificates: [],
      }
    },

    computed: {
      ...mapState('revokeListState', [
        'sizePage',
        'activePage',
        'totalPages',
        'isLoaded',
      ]),
      ...mapGetters('revokeListState', ['getApplicationList']),
      ...mapGetters('tableState', ['getColumnTitles']),
      ...mapGetters('authorizationState', ['getRole']),
      ...mapGetters('filterState', ['getFilterInfoString', 'isActiveFilter']),

      savePagination() {
        return this.$route.params.savePagination || false;
      },

      defaultContainerWidth() {
        return window.localStorage.getItem(`revokes-container-width`) ||
        (MIN_CONTAINER_WIDTH + 'px');
      },

      defaultCellsWidth() {
        const columns = TABLE_COLUMNS[PAGE_SHORT_NAMES.revokes][this.getRole] ?? TABLE_COLUMNS[PAGE_SHORT_NAMES.revokes][ROLES.Manager];

        return columns.map((column, index) => (
          window.localStorage.getItem(`revokes-column-${index}-width`) ||
          column.part * 100 + '%'
        ))
      },


      reasonOptions() {
        return Object.keys(REVOKE_REASON_TYPE_IDS)
                .filter(t => !isNaN(Number(t)))
                .map(t => ({value: t, label: REVOKE_REASON_TYPES[t]}));
      },

      foundCertificatesOptions() {
        return this.foundCertificates.map(cert => ({
          value: {
                  serialNumber: cert.serialNumber,
                  phone: cert.phone,
                  thumbprint: cert.thumbprint,
                },
          label: [
                  cert.serialNumber,
                  cert.fullName,
                  `действует с ${getFormattedDate(cert.validFrom, 'L')}
                   по ${getFormattedDate(cert.validTo, 'L')}`,
                   cert.validation?.isValid ? 'Валидный' : 'Невалидный',
                   cert.validation?.errors.length ? cert.validation.errors.join('. ') : null
                ].filter(Boolean),
        }))
      }
    },

    methods: {
      ...mapActions('revokeListState', [
        'getList',
        'changePage',
        'changeSizePage',
        'setActivePage',
        'getReport',
        'getCertificatesByFilter',
        'createNewRevoke',
      ]),
      ...mapActions('tableState', ['setTableContentType']),
      ...mapActions('filterState', ['parseFilterFromLocaleStorage']),

      showCreateRevokeModal() {
        this.$modal.show("modal-get-certificates-by-snils");
      },

      async getCertificatesBySnilsHandler({searchBy, search}) {
        this.foundCertificates = await this.getCertificatesByFilter({searchBy, search});
      },

      async createNewRevokeHandler({reason, certificate}) {
        const number = await this.createNewRevoke({
          reason,
          serialNumber: certificate.serialNumber,
          phone: certificate.phone,
          thumbprint: certificate.thumbprint,
          identificationType: REVOKE_IDENTIFICATION_TYPE_IDS.Direct
        });

        if (number !== null) router.push(`/revoke/A${number}`);
      },
    },

    beforeMount() {
      this.setTableContentType(PAGE_SHORT_NAMES.revokes);
    },

    mounted() {
      this.parseFilterFromLocaleStorage('revokes');
      this.getList();
    },

    destroyed() {
      this.foundCertificates = [];
    },

    watch: {
      foundCertificates() {
        if (!this.foundCertificates.length) return;
        this.$modal.show("modal-choose-certificate-to-revoke");
      }
    },
  });
</script>

<style lang='scss'>
  #revokes-container {
    position: relative;
    min-width: 1400px;
    max-width: 1900px;

    .resizer {
      position: absolute;
      height: 100%;
      top: 0;
      width: 5px;
      cursor: col-resize;

      &.resizer-right {
        right: 5px;
      }

      &.resizer-left {
        left: 5px;
      }
    }  
  }

  .list-requests {
    margin-top: 10px;

    .pager-wrap {
      display: flex;
      justify-content: space-between;
      margin-top: 45px;

      .select {
        display: flex;
        align-items: center;
        font-size: 14px;
        line-height: 17px;

        .form-item-select {
          &__control {
            height: auto;
            background: none;
            border: none;
          }

          &__container {
            padding: 0 0 0 5px;
          }

          &__value {
            color: #1e72c2;
          }

          &__indicators {
            span::before {
              border-color: #1e72c2;
              border-width: 0 2px 2px 0;
            }
          }

          &__input {
            input {
              height: 0;
              width: 0;
            }
          }
        }
      }
    }

    .form-wizard-content--progress {
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -21px 0 0 -27px;
    }

    a.button, .button-create {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 48px;
      padding: 0 20px;
      background: linear-gradient(263.67deg, #7a6afa 1.17%, #607aff 99.07%);
      border-radius: 4px;
      color: #ffffff;
      text-decoration: none;
      text-transform: uppercase;

      &:hover {
        background: linear-gradient(263.67deg, #7a6afa 1.17%, #607aff 99.07%);
        color: #ffffff;
      }

      .icon-plus {
        display: inline-block;
        width: 12px;
        height: 12px;
        margin-right: 5px;
        background: url('/img/icon/plus.svg') no-repeat center/cover;
      }
    }

    .form-item.search {
      .input-wrap {
        position: relative;
        width: 425px;

        &::before {
          content: '';
          position: absolute;
          top: 50%;
          right: 15px;
          margin-top: -6px;
          opacity: 1;
          width: 12px;
          height: 12px;
          background: url('/img/icon/search.svg') no-repeat center;
        }
      }

      input {
        padding-right: 35px;
      }
    }

    &-empty {
      width: 580px;
      margin: 170px auto 0;
      text-align: center;

      .title {
        font-weight: 700;
        font-size: 26px;
        color: #000;
      }

      .descr {
        line-height: 25px;
      }

      .controls {
        margin-top: 40px;

        .button {
          display: inline-flex;
        }
      }
    }

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;

      .controls {
        display: flex;
        align-items: center;

        & > * + * {
          margin-left: 15px;
        }
      }

      .title {
        font-size: 25px;
        line-height: 43px;
        color: #000;

        span {
          display: block;
          font-size: 12px;
          line-height: 21px;
          color: #808080;
        }
      }
    }
  }
</style>
